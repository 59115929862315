import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Routes from 'enums/Routes';
import { pupilRoleTableColumns } from 'constants/tableColumns';
import PagesList from 'enums/PagesList';
import { getPeriodTimeString } from 'utils/format/timeFormat';
import { getSelectedPupilAllInfo } from 'store/registry_ob/pupilPerson';
import initialSortTable from 'constants/sortTable';
import Table from 'components/table/Table';
import { getStatus } from 'utils/mappers/getStatus';

const PupilRoleList = () => {
  const navigate = useNavigate();

  let pupilPerson = useSelector(getSelectedPupilAllInfo);

  pupilPerson = pupilPerson
    .filter((item: any) => {
      return item.resource.resourceType === 'PupilRole';
    })
    .map((item: any) => {
      return item.resource;
    });

  const getRowData = useMemo(() => {
    return (
      pupilPerson?.map((item: any) => {
        const { id, code, status, period, educationalProgram } = item;
        const { start, end } = period;
        return {
          id,
          name: code?.display,
          educationalProgram: educationalProgram[0]?.display,
          status: getStatus(status),
          period: getPeriodTimeString(start, end),
        };
      }) || []
    );
  }, [pupilPerson]);

  const showPupilRole = (id: string) => {
    navigate(`${Routes.PUPIL_ROLE_PUPIL}/${id}`);
  };

  return (
    <Table
      withSearch={false}
      totalItems={0}
      numberOfPage={0}
      page={0}
      columns={pupilRoleTableColumns}
      rowData={getRowData}
      componentName={PagesList.PupilRole}
      onChangePage={() => {}}
      sortTable={initialSortTable}
      onSetSortOrder={() => {}}
      rowAction={showPupilRole}
      contextMenuItems={undefined}
      paginationNeeded={false}
    />
  );
};

export default PupilRoleList;
