/* eslint-disable no-underscore-dangle */
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FilterWrapper from 'components/filterWrapper';
import s from 'components/commonStyles/filter.module.scss';
import { CustomDatePicker, CustomSelect, CustomTextField } from 'components/fields';
import { OrgFilterModel } from 'interfaces/';
import { getOrganizationFilter, organizationActions } from 'store/registry_oood/organization';
import { labels } from 'constants/labels';
import { booleanSelectValues, getBooleanValueByFieldName } from 'constants/selectValuesMap';
import {regexpPostalCode} from "../../../../../constants/regexp";

const { setOrgFilter, clearFilter, fetchOrganizationData } =
  organizationActions;
type Props = {
  isOpen: boolean;
  onClose: (isChanges: boolean) => void;
};

const OrgFilter: React.FC<Props> = ({ isOpen, onClose }) => {
  const organizationFilter = useSelector(getOrganizationFilter);
  const [filter, setFilter] = useState<Partial<OrgFilterModel>>(
    organizationFilter || {
      'name:contains': '',
      lastUpdated: '',
      active: '',
      address: '',
      'address-city': '',
      'address-country': '',
      'address-postalcode': '',
      'address-state': '',
      identifier: '',
      partof: '',
    }
  );
  const [isValidate, setValidate] = useState(true);

  const dispatch = useDispatch();

  const booleanData = booleanSelectValues.map((item) => item.key);

  const inputHandler = useCallback(
    (name: string, value: string | boolean) => {
      if (name === 'active') {
        const obj = getBooleanValueByFieldName('key', value);
        value = obj?.value || false;
      }
      setFilter({
        ...filter,
        [name]: value,
      });
    },
    [filter]
  );

  const onFetchOrganizationDataWithFilter = (
    newFilter: Partial<OrgFilterModel>
  ) => {
    dispatch(setOrgFilter({ filter: newFilter }));
    dispatch(fetchOrganizationData({ page: 1 }));
    onClose(true);
  };

  const activeValue = useMemo(() => {
    const item = getBooleanValueByFieldName('value', filter.active);
    return item?.key || '';
  }, [filter.active]);

  const onSubmit = () => {
    if (filter["address-postalcode"]?.length) {
      const isValid = regexpPostalCode.test(filter["address-postalcode"]);
      setValidate(isValid);
      return isValid && onFetchOrganizationDataWithFilter(filter);
    }
    setValidate(true);
    return onFetchOrganizationDataWithFilter(filter);
  };

  const onClearFilter = () => {
    setFilter({} as OrgFilterModel);
    setValidate(true);
    dispatch(clearFilter());
    onClose(true);
  };

  const fields = useMemo(() => {
    return (
      <>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.dateFilter}</span>
          <CustomDatePicker
            className={s.field}
            name="_lastUpdated"
            inputGrid={24}
            onDateChange={inputHandler}
            defaultValue={filter._lastUpdated || ''}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.orgNameFilter}</span>
          <CustomTextField
            className={s.field}
            name="name:contains"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter['name:contains'] || ''}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.activeFilter}</span>
          <CustomSelect
            className={s.field}
            name="active"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={activeValue}
            data={booleanData}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.addressFullFilter}</span>
          <CustomTextField
            className={s.field}
            name="address"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter.address || ''}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.addressCityFilter}</span>
          <CustomTextField
            className={s.field}
            name="address-city"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter['address-city'] || ''}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.addressCountryFilter}</span>
          <CustomTextField
            className={s.field}
            name="address-country"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter['address-country'] || ''}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.addressPostalCodeFilter}</span>
          <CustomTextField
            className={s.field}
            name="address-postalcode"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter['address-postalcode'] || ''}
            isValidateError={!isValidate}
            errorMessage={!isValidate ? labels.errorPostalcodeMessage : ''}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.addressStateFilter}</span>
          <CustomTextField
            className={s.field}
            name="address-state"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter['address-state'] || ''}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.identifierOrgFilter}</span>
          <CustomTextField
            className={s.field}
            name="identifier"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter.identifier || ''}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.partOfFilter}</span>
          <CustomTextField
            className={s.field}
            name="partof"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter.partof || ''}
          />
        </div>
      </>
    );
  }, [activeValue, booleanData, filter, inputHandler, isValidate]);

  return (
    <FilterWrapper
      isOpen={isOpen}
      onClose={onClose}
      okAction={onSubmit}
      cancelAction={onClearFilter}
    >
      {fields}
    </FilterWrapper>
  );
};

export default OrgFilter;
