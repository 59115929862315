export const labels = {
  systemId: 'Системный ИД',
  id: 'ID',
  lastUpdated: 'По состоянию на',
  active: 'Активна',
  activeStatus: 'Признак активности',
  addressURL: 'Адресс URL',
  addresses: 'Адреса',
  name: 'Наименование',
  alias: 'Другое наименование',
  partOf: 'Головная организация',
  addressText: 'Полный адрес',
  addressCity: 'Населенный пункт',
  addressDistrict: 'Район',
  addressPostalCode: 'Почтовый индекс',
  addressStreet: 'Улица',
  addressHouse: 'Дом',
  addressBuilding: 'Корпус (строение)',
  addressFlat: 'Квартира (офис)',
  executiveName: 'ФИО руководителя',
  executivePosition: 'Должность',
  systemTelecom: 'Тип контакта',
  useTelecom: 'Тип использования',
  valueTelecom: 'Контакт',
  periodStart: 'с ',
  periodEnd: 'по ',
  start: 'Начало',
  contacts: 'Контакты',
  identifiers: 'Идентификаторы',
  systemIdentifier: 'Схема идентификации',
  valueIdentifier: 'Значение идентификатора',
  typeIdentifier: 'Тип идентификатора',
  period: 'Период действия',
  licenseType: 'Тип лицензируемой деятельности',
  periodRelationship: 'Период отношения',
  periodEvent: 'Период события',
  status: 'Статус',
  academYear: 'Академический год',
  description: 'Описание',
  fullName: 'ФИО',
  region: 'Регион',
  dateFilter: 'Дата последнего изменения',
  orgNameFilter: 'Часть наименования организации',
  personFilter: 'Часть наименования ИП',
  activeFilter: 'Активна ли запись организации',
  addressFullFilter: 'Адрес(общий)',
  addressCityFilter: 'Город, указанный в адресе',
  addressCountryFilter: 'Страна, указанная в адресе',
  addressPostalCodeFilter: 'Почтовый индекс, указанный в адресе',
  addressStateFilter: 'Регион, указанный в адресе',
  addressUseFilter: 'Код использования, указанный в адресе',
  identifierOrgFilter: 'Любой идентификатор организации',
  identifierPersonFilter: 'Идентификатор лица',
  assignerDisplay: 'Ответственная организация',
  partOfFilter:
    'Ссылка на организацию, частью которой является данная организация',
  birthdayFilter: 'Дата рождения лица',
  emailFilter: 'Адрес электронной почты',
  genderFilter: 'Пол',
  identifierFilter: 'Идентификатор лица',
  phoneFilter: 'Номер телефона',
  telecomFilter: 'Поиск по значению любой контактной информации',
  type: 'Тип',
  reason: 'Причина',
  reasonStatus: 'Причина статуса',
  member: 'Учредитель',
  adaptation: 'Приспособление',
  predecessor: 'Правопредшественник',
  codeableConcept: 'Значение кода',
  codingSystem: 'Система кодирования',
  connectionType: 'Протокол',
  codingVersion: 'Версия',
  codingCode: 'Код',
  assurance: 'Степень уверености в идентичности лиц',
  codingDisplay: 'Описание кода',
  mainEconomicActivity: 'Основная экономическая деятельность',
  otherEconomicActivity: 'Другие экономические деятельности',
  viewEducationalEntityCard: 'Просмотр страницы реестровой записи',
  viewOrganizationCard: 'Просмотр страницы организации',
  viewEndpointCard: 'Просмотр страницы электронных сервисов',
  viewPersonCard: 'Просмотр страницы индивидуального предпринимателя',
  viewLicenseCard: 'Просмотр страницы лицензии',
  viewLicenseSupplementCard: 'Просмотр страницы приложения лицензии',
  viewAccreditationSupplementCard:
    'Просмотр страницы приложения к свидетельству',
  viewAccreditationCertificateCard:
    'Просмотр страницы свидетельства государственной организации',
  viewEduLocationCard:
    'Просмотр страницы мест осуществления образовательной деятельности',
  viewEventCard: 'Просмотр страницы события',
  viewEduGroupCard: 'Просмотр страницы ученических мест',
  viewPositionCard: 'Просмотр страницы штатного расписания',
  viewPupilPersonCard: 'Просмотр страницы обучающегося',
  viewPupilRoleCard: 'Просмотр страницы ролей обучающихся',
  viewNextOfKinPersonCard: 'Просмотр страницы законного представителя',
  viewNextOfKinCard: 'Просмотр страницы ролей законного представителя',
  viewEduProviderPersonCard: 'Просмотр страницы педагогического работника',
  viewEduProviderRoleCard: 'Просмотр страницы роли',
  viewEduProviderQualificationCard: 'Просмотр страницы квалификации',
  viewEduProviderRestrictionCard: 'Просмотр страницы ограничения',
  viewEduProviderEventCard: 'Просмотр страницы события',
  address: 'Местонахождение',
  mediaId: 'Серия и номер',
  mediaSupplementId: 'Серия и номер бланка или электронного свидетельства',
  sequence: 'Порядковый номер',
  educationalProgram: 'Образовательная программа',
  educationalEntity: 'Образовательная организация',
  educationalEntityReference: 'Лицензиат или его филиал',
  eduLocationAddress: 'Адреса мест осуществления образовательной деятельности',
  licensedProgramKind: 'Лицензируемый вид образовательной деятельности',
  eventSubject: 'Субъект события',
  eventTargetStatus: 'Статус, присвоенный субъекту в результате события',
  docDate: 'Дата принятия',
  docName: 'Наименование документа',
  eventReference: 'URL',
  mode: 'Режим',
  managingOrganization: 'Ответственная организация',
  coordinates: 'Географические координаты',
  latitude: 'Широта',
  longitude: 'Долгота',
  url: 'Идентификатор',
  totalPlaces: 'Общее число ученических мест',
  places: 'Ученические места',
  version: 'Версия',
  kind: 'Код формы',
  fte: 'Ставка',
  totalFte: 'Общее число ставок',
  totalRate: 'Общая сумма окладов',
  totalAllowanceValue: 'Общая сумма надбавок',
  totalSalary: 'Общая сумма заработной платы',
  totalExtraBudgetary: 'Из них внебюджет',
  note: 'Примечание',
  parent: 'Головная организация',
  linkPerson:
    'Ссылка на основные демографические данные педагогического работника',
  linkPupilPerson:
    'Ссылка на другой экземпляр Person, содержащий сведения об этом же лице',
  linkToEduProvider: 'Ссылка на реестровую запись',
  linkToOrganization: 'Организация, частью которой является данная организация',
  linkEduProvider: 'Ссылка на объединенную реестровую запись',
  linkEducationalEntity: 'Ссылка на реестровую запись ОООД',
  linkInsteadOfEvent: 'Ссылка на заменяемое событие',
  physicalType: 'Физическая форма',
  birthDate: 'Дата рождения',
  nationality: 'Гражданство',
  pupilLocation: 'Место выполнения роли',
  rationale: 'Причина',
  relationship: 'Тип отношения',
  custodian: 'Ссылка на организацию',
  personData: 'Личные данные',
  pupilDataLink: 'Ссылка на реестровую запись обучающегося',
  pupilData: 'Обучающийся',
  roleName: 'Роль',
  employment: 'Работа',
  contact: 'Служба поддержки',
  payloadType: 'Тип содержания',
  specialty: 'Специальность',
  eduGroup: 'Ученическое место',
  endpoint: 'Электронные сервисы',
  quantity: 'Педагогический стаж',
  attendance: 'Форма обучения',
  organizationUnitCode: 'Код подразделения',
  organizationUnitName: 'Наименование подразделения',
  organizationUnitPartOf: 'Код вышестоящего подразделения',
  allowanceCode: 'Код надбавки',
  allowanceValue: 'Сумма надбавки',
  allowanceDescription: 'Описание надбавки',
  positionCode: 'Код должности',
  positionName: 'Наименование должности',
  positionFte: 'Количество штатных единиц',
  positionRate: 'Ставка',
  positionSalary: 'Всего в месяц',
  positionExtraBudget: 'Из них внебюджет',
  positionNote: 'Примечание',
  positionAllowance: 'Надбавки для данной должности',
  propertyType: 'Тип данных',
  propertySequence: '№№',
  propertyLabel: 'Наименование характеристики',
  propertyDescription: 'Описание характеристики',
  propertyValueCoding: 'Кодирумое значение',
  propertyValueString: 'Строковое значение',
  propertyCode: 'Код характеристики',
  isLast: 'Признак выпускной группировки',
  family: 'Фамилия',
  namePerson: 'Имя',
  middle: 'Отчество',
  snils: 'СНИЛС',
  typeDocument: 'Тип документа, удостоверяющего личность',
  requisiteDocument: 'Реквизиты документа, удостоверяющего личность',
  login: 'Логин',
  password: 'Пароль',
  entry: 'Вход в систему',
  errorUserValidation: 'Неверные данные для входа',
  errorEntityNotFound: 'Запись не найдена',
  errorRequestedEntityNotFound: 'Запрашиваемый ресурс не найден',
  errorPostalcodeMessage: 'Почтовый индекс должен содержать 6 цифр',
  errorSnilsMessage: 'Снилс должен содержать 10 цифр без разделителей',
  errorPhoneNumberMessage: 'Номер телефона не соответствует шаблону',
  errorEmailMessage: 'Адрес электронной почты не соответствует шаблону',
  actions: {
    add: 'Добавить',
    submitEntry: 'Войти в систему',
    create: 'Создать',
    submit: 'Подтвердить',
    save: 'Сохранить',
    import: 'Импортировать',
    edit: 'Редактировать',
    view: 'Просмотреть',
    remove: 'Удалить',
    newEntry: 'Новая запись',
    filter: 'Фильтр',
    search: 'Поиск',
    cancel: 'Отмена',
    yes: 'Да',
    close: 'Закрыть',
    enterValue: 'Введите значение',
    reset: 'Сбросить',
    apply: 'Применить',
    login: 'Войти',
    try_login: 'Повторить вход',
    go_home: 'Вернуться на главную',
    getBack: 'Вернуться назад',
    reload: 'Перезапустить',
  },
  statusValues: {
    draft: 'Проект',
    active: 'Действует',
    retired: 'Прекращена',
    enteredInError: 'Создана по ошибке',
    pending: 'На рассмотрении',
    cancelled: 'Отменена',
    rejected: 'Отклонена',
    suspended: 'Действие приостановлено',
    inactive: 'Не действует',
  },
  contactValues: {
    phone: 'Телефон',
    fax: 'Факс',
    email: 'Электронная почта',
    pager: 'Пейджер',
    url: 'URL',
    sms: 'СМС',
    other: 'Другой',
  }
};
