import { EDU_PROVIDER_PERSON, edu, PERSON } from 'constants/apiConstants';
import { instanceAxios } from '../root.service';
import { SortTableModel } from '../../interfaces';
import initialSortTable from '../../constants/sortTable';
import { RelEduProvider } from '../../constants/common';

const prefix = edu + EDU_PROVIDER_PERSON;
const prefixPerson = edu + PERSON;
const DEFAULT_COUNT = 10

export const getEduProviderPersonList = (
  offset: number,
  filter?: string,
  persons?: string,
  count: number = 10,
  sorting: SortTableModel = initialSortTable,
) => {
  const include = `?_include=EduProvider:person`;
  const { column, direction } = sorting;
  const newColumn = column === 'id' ? '_id' : column;
  const sort = newColumn
    ? `&_sort=${direction === 'desc' ? '' : '-'}${newColumn}`
    : '';
  const personsString = persons ? `&person=${persons}` : '';
  return instanceAxios.get(
    `${prefix}${include}&_offset=${
      (offset - 1) * count
    }&_count=${count}${personsString}${sort}&${filter}`
  );
};

export const getEduProviderPersonById = (id: string) => {
  return instanceAxios.get(
    `${prefix}/?_id=${id}&${RelEduProvider}`
  );
};

export const getEduProviderPersonListWithFilter = (
  params: {
    offset: number,
    filter?: string
    count?: number
  }
) => {
  const {offset, filter, count} = params;
  const currentCount = count ?? DEFAULT_COUNT;
  return instanceAxios.get(`${prefixPerson}?${filter}`, {
    params: {
      _offset: (offset - 1) * currentCount,
      _count: currentCount,
    }
  });
};

export const getEduProviderHash = (data: any) => {
  return instanceAxios.post('https://gw.edu.iteco.dev/hash', data);
};
