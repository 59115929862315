import { labels } from './labels';

export const organizationTableColumns = [
  {
    dataKey: 'id',
    label: labels.systemId,
    width: 75,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'status',
    label: labels.status,
    width: 100,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'name',
    label: labels.name,
    width: 100,
    defaultVisible: true,
    sortable: false,
  },
  {
    dataKey: 'addressText',
    label: labels.address,
    width: 100,
    defaultVisible: true,
    sortable: false,
  },
];

export const personTableColumns = [
  {
    dataKey: 'id',
    label: labels.systemId,
    width: 75,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'status',
    label: labels.status,
    width: 100,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'state',
    label: labels.region,
    width: 75,
    defaultVisible: true,
    sortable: false,
  },
  {
    dataKey: 'fullName',
    label: labels.fullName,
    width: 270,
    defaultVisible: true,
    sortable: false,
  },
  {
    dataKey: 'addressText',
    label: labels.addressText,
    width: 100,
    defaultVisible: true,
    sortable: false,
  },
];

export const accreditationCertificateTableColumns = [
  {
    dataKey: 'period',
    label: labels.period,
    width: 200,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'status',
    label: labels.status,
    width: 270,
    defaultVisible: true,
    sortable: true,
  },
];

export const licenseTableColumns = [
  {
    dataKey: 'period',
    label: labels.period,
    width: 270,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'status',
    label: labels.status,
    width: 75,
    defaultVisible: true,
    sortable: true,
  },
];
export const positionListTableColumns = [
  {
    dataKey: 'academYear',
    label: labels.academYear,
    width: 270,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'period',
    label: labels.period,
    width: 75,
    defaultVisible: true,
    sortable: true,
  },
];
export const eduGroupTableColumns = [
  {
    dataKey: 'academYear',
    label: labels.academYear,
    width: 270,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'period',
    label: labels.period,
    width: 75,
    defaultVisible: true,
    sortable: true,
  },
];
export const eduLocationTableColumns = [
  {
    dataKey: 'name',
    label: labels.name,
    width: 270,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'description',
    label: labels.description,
    width: 75,
    defaultVisible: true,
    sortable: true,
  },
];
export const eventTableColumns = [
  {
    dataKey: 'type',
    label: labels.type,
    width: 270,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'reason',
    label: labels.reason,
    width: 75,
    defaultVisible: true,
    sortable: true,
  },
];

export const educationalEntityTableColumns = [
  {
    dataKey: 'id',
    label: labels.systemId,
    width: 75,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'lastUpdated',
    label: labels.lastUpdated,
    width: 270,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'status',
    label: labels.status,
    width: 100,
    defaultVisible: true,
    sortable: true,
  },
];

export const licenseSupplementTableColumns = [
  {
    dataKey: 'sequence',
    label: labels.sequence,
    width: 75,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'mediaId',
    label: labels.mediaId,
    width: 270,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'status',
    label: labels.status,
    width: 100,
    defaultVisible: true,
    sortable: true,
  },
];
export const accreditationSupplementTableColumns = [
  {
    dataKey: 'sequence',
    label: labels.sequence,
    width: 75,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'mediaId',
    label: labels.mediaId,
    width: 270,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'status',
    label: labels.status,
    width: 100,
    defaultVisible: true,
    sortable: true,
  },
];
export const pupilPersonTableColumns = [
  {
    dataKey: 'id',
    label: labels.systemId,
    width: 75,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'status',
    label: labels.status,
    width: 75,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'contact',
    label: labels.contacts,
    width: 100,
    defaultVisible: true,
    sortable: false,
  },
];

export const nextOfKinPersonTableColumns = [
  {
    dataKey: 'id',
    label: labels.systemId,
    width: 75,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'contact',
    label: labels.contacts,
    width: 100,
    defaultVisible: true,
    sortable: true,
  },
];

export const nextOfKinTableColumns = [
  {
    dataKey: 'relationship',
    label: labels.relationship,
    width: 75,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'period',
    label: labels.periodRelationship,
    width: 100,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'status',
    label: labels.status,
    width: 100,
    defaultVisible: true,
    sortable: true,
  },
];

export const eduProviderRoleTableColumns = [
  {
    dataKey: 'code',
    label: labels.roleName,
    width: 75,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'name',
    label: labels.name,
    width: 100,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'location',
    label: labels.pupilLocation,
    width: 100,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'period',
    label: labels.period,
    width: 100,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'status',
    label: labels.status,
    width: 100,
    defaultVisible: true,
    sortable: true,
  },
];

export const pupilRoleTableColumns = [
  {
    dataKey: 'name',
    label: labels.roleName,
    width: 100,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'educationalProgram',
    label: labels.educationalProgram,
    width: 100,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'period',
    label: labels.period,
    width: 100,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'status',
    label: labels.status,
    width: 100,
    defaultVisible: true,
    sortable: true,
  },
];

export const eduProviderPersonTableColumns = [
  {
    dataKey: 'id',
    label: labels.systemId,
    width: 75,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'status',
    label: labels.status,
    width: 100,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'contact',
    label: labels.contacts,
    width: 100,
    defaultVisible: true,
    sortable: false,
  },
];

export const eduProviderQualificationTableColumns = [
  {
    dataKey: 'type',
    label: labels.type,
    width: 75,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'code',
    label: labels.codingCode,
    width: 100,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'name',
    label: labels.name,
    width: 100,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'period',
    label: labels.period,
    width: 100,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'status',
    label: labels.status,
    width: 100,
    defaultVisible: true,
    sortable: true,
  },
];

export const eduProviderRestrictionTableColumns = [
  {
    dataKey: 'type',
    label: labels.type,
    width: 75,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'rationale',
    label: labels.rationale,
    width: 100,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'period',
    label: labels.period,
    width: 100,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'status',
    label: labels.status,
    width: 100,
    defaultVisible: true,
    sortable: true,
  },
];

export const eduProviderEventTableColumns = [
  {
    dataKey: 'type',
    label: labels.type,
    width: 75,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'reason',
    label: labels.reason,
    width: 100,
    defaultVisible: true,
    sortable: true,
  },
];

export const endpointColumns = [
  {
    dataKey: 'name',
    label: labels.name,
    width: 75,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'status',
    label: labels.status,
    width: 100,
    defaultVisible: true,
    sortable: true,
  },
  {
    dataKey: 'address',
    label: labels.addressURL,
    width: 100,
    defaultVisible: true,
    sortable: true,
  },
];

export const organizationUnitTableColumns = [
  {
    dataKey: 'code',
    label: labels.organizationUnitCode,
    width: 100,
    defaultVisible: true,
    sortable: false,
  },
  {
    dataKey: 'name',
    label: labels.organizationUnitName,
    width: 100,
    defaultVisible: true,
    sortable: false,
  },
  {
    dataKey: 'partOf',
    label: labels.organizationUnitPartOf,
    width: 100,
    defaultVisible: true,
    sortable: false,
  },
];

export const allowanceTableColumns = [
  {
    dataKey: 'code',
    label: labels.allowanceCode,
    width: 100,
    defaultVisible: true,
    sortable: false,
  },
  {
    dataKey: 'description',
    label: labels.allowanceDescription,
    width: 100,
    defaultVisible: true,
    sortable: false,
  },
];

export const positionTableColumns = [
  {
    dataKey: 'name',
    label: labels.positionName,
    width: 100,
    defaultVisible: true,
    sortable: false,
  },
  {
    dataKey: 'rate',
    label: labels.positionRate,
    width: 100,
    defaultVisible: true,
    sortable: false,
  },
  {
    dataKey: 'salary',
    label: labels.positionSalary,
    width: 100,
    defaultVisible: true,
    sortable: false,
  },
];

export const positionAllowanceTableColumns = [
  {
    dataKey: 'code',
    label: labels.allowanceCode,
    width: 100,
    defaultVisible: true,
    sortable: false,
  },
  {
    dataKey: 'value',
    label: labels.allowanceValue,
    width: 100,
    defaultVisible: true,
    sortable: false,
  },
];

export const eduLocationPropertyTableColumns = [
  {
    dataKey: 'sequence',
    label: labels.propertySequence,
    width: 100,
    defaultVisible: true,
    sortable: false,
  },

  {
    dataKey: 'code',
    label: labels.propertyCode,
    width: 100,
    defaultVisible: true,
    sortable: false,
  },

  {
    dataKey: 'type',
    label: labels.propertyType,
    width: 100,
    defaultVisible: true,
    sortable: false,
  },

  {
    dataKey: 'label',
    label: labels.propertyLabel,
    width: 100,
    defaultVisible: true,
    sortable: false,
  },

  {
    dataKey: 'description',
    label: labels.propertyDescription,
    width: 100,
    defaultVisible: true,
    sortable: false,
  },
  {
    dataKey: 'valueCoding',
    label: labels.propertyValueCoding,
    width: 100,
    defaultVisible: true,
    sortable: false,
  },
  {
    dataKey: 'valueString',
    label: labels.propertyValueString,
    width: 100,
    defaultVisible: true,
    sortable: false,
  },
];

export const eduEntityTableColumns = [
  {
    dataKey: 'id',
    label: labels.systemId,
    width: 75,
    defaultVisible: true,
    sortable: false,
  },
  {
    dataKey: 'status',
    label: labels.status,
    width: 100,
    defaultVisible: true,
    sortable: false,
  },
];
