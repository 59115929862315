import { call, put, select, takeLatest, takeLeading } from 'redux-saga/effects';
import { stringify as stringifyQS } from 'qs';
import {
  getPupilHash,
  getPupilPersonById,
  getPupilPersonList,
  getPupilPersonListWithFilter,
} from 'services/registry_ob/pupilPerson.service';
import objectDifference from 'utils/objectDifference';
import { BundleEntry, SortTableModel } from 'interfaces/';
import { appActions, getPageSize } from 'store/app';
import { notifierLabels } from 'constants/labels/notifierLabels';
import { dispatchNotification } from 'utils/dispatchErrorNotification';
import { getHashPayload, HashPayload } from 'constants/parametersHash';
import { getNewFilter } from 'utils/getNewFilter';
import Type from 'enums/Type';
import { initialFilterData } from './initialData';
import {
  getFilterType,
  getPupilPersonFilter,
  pupilPersonActions,
} from './index';
import { getTableSortOrder } from './selectors';

const { setLoading } = appActions;
const {
  setPupilPersonList,
  fetchPupilPersonData,
  fetchSelectedPupilPerson,
  setSelectedPupilPerson,
  fetchHashPersonFilter,
  setSelectedAllPupilInfo,
  fetchPupilDataWithFilter,
  fetchFilterType,
  setIsFilterType,
  setTableSortOrder,
  fetchAfterFilter,
  setFilterQueryParam
} = pupilPersonActions;

function* getPupilPersonData(action: {
  payload: { page: number; sorting?: SortTableModel };
}): any {
  try {
    const pageSize = yield select(getPageSize);
    yield put(setLoading({ loading: true }));
    const filter = yield select(getPupilPersonFilter);
    const searchValue = stringifyQS(
      objectDifference(getNewFilter(filter), initialFilterData)
    );
    yield put(setFilterQueryParam(searchValue));
    const pupils = yield call(
      getPupilPersonList,
      action.payload.page,
      searchValue,
      '',
      pageSize,
      action.payload.sorting
    );
    const { entry, total: totalItems } = pupils.data;
    const resources = entry?.map((item: BundleEntry) => item.resource);
    yield put(
      setPupilPersonList({
        data: resources,
        totalItems,
        pageNumber: action.payload.page,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorListPupilPerson));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* getSelectedPupilPerson(action: { payload: { id: string } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const pupilPerson = yield call(getPupilPersonById, action.payload.id);
    yield put(
      setSelectedPupilPerson({
        pupil: pupilPerson.data.entry.filter((item: any) => {
          return item.resource.resourceType === Type.Pupil;
        })[0].resource,
      })
    );
    yield put(
      setSelectedAllPupilInfo({
        pupilInfo: pupilPerson.data.entry,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorSelectedPupilPerson));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* getHashPupilPerson(action: {
  payload: { data: any; page: number; sorting?: SortTableModel };
}): any {
  try {
    const { page, data } = action.payload;
    const pageSize = yield select(getPageSize);
    yield put(setLoading({ loading: true }));
    const filter = yield select(getPupilPersonFilter);
    const searchValue = stringifyQS(
      objectDifference(getNewFilter(filter), initialFilterData)
    );
    yield put(setFilterQueryParam(searchValue));
    const hash = yield call(getPupilHash, data);
    const hashIdentifiers: string[] = [];
    hash.data.identifier.map((item: any) => {
      return hashIdentifiers.push(item.value);
    });

    const searchValueHash = `identifier=${hashIdentifiers.join(',')}&`;

    const pupils = yield call(
      getPupilPersonListWithFilter,
      {
        offset: page,
        filter: `${searchValueHash}${searchValue}`
      }
    );

    const filterPersons = pupils?.data?.entry;
    yield put(
      fetchAfterFilter({
        filterPersons,
        sorting: action.payload?.sorting,
        page,
        pageSize,
      })
    );
  } catch (e) {
    yield put(yield put(dispatchNotification(notifierLabels.errorHash)));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* getPupilPersonDataWithFilter(action: {
  payload: { page: number; sorting?: SortTableModel };
}): any {
  try {
    const { page } = action.payload;
    const pageSize = yield select(getPageSize);
    yield put(setLoading({ loading: true }));
    const filter = yield select(getPupilPersonFilter);
    const searchValue = stringifyQS(
      objectDifference(getNewFilter(filter), initialFilterData)
    );
    yield put(setFilterQueryParam(searchValue));
    const pupils = yield call(getPupilPersonListWithFilter, {offset: page, filter: searchValue, count: pageSize});

    const filterPersons = pupils?.data?.entry;
    const total = pupils?.data?.total
    yield put(
      fetchAfterFilter({
        filterPersons,
        sorting: action.payload?.sorting,
        page: 1,
        pageSize,
        totalItems: total
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorListPupilPerson));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* onAfterFilter(action: {
  payload: {
    page: number;
    filterPersons: any;
    pageSize: number;
    sorting?: SortTableModel;
    totalItems?: number
  };
}): any {
  try {
    const { page, filterPersons, pageSize } = action.payload;
    let filterPersonsId = [];
    let pupilWithFilter: any = [];

    if (filterPersons) {
      filterPersonsId = filterPersons.map((item: any) => {
        return item.resource.id;
      });

      const personsId = `${filterPersonsId.join(',')}`;

      pupilWithFilter = yield call(
        getPupilPersonList,
        page,
        '',
        personsId,
        pageSize,
        action?.payload?.sorting
      );
    }

    const { entry, total: totalItems } = pupilWithFilter.data || [];
    const resources = entry && entry?.map((item: BundleEntry) => item.resource);
    if (filterPersons) {
      filterPersons.map((item: any) => {
        return resources?.push(item.resource);
      });
    }

    yield put(
      setPupilPersonList({
        data: resources || [],
        totalItems: action.payload.totalItems ?? totalItems,
        pageNumber: action.payload.page,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorListEduProviderPerson));
  }
}

function* filterType(action: { payload: { filterType: string | undefined } }) {
  yield put(setIsFilterType({ filterType: action.payload.filterType }));
}

function* sort(): any {
  const sorting = yield select(getTableSortOrder);
  const filterType = yield select(getFilterType);
  const filter = yield select(getPupilPersonFilter);
  const params: HashPayload = getHashPayload(filter);

  if (filterType === 'filter') {
    yield put(
      fetchPupilDataWithFilter({
        page: 1,
        sorting,
      })
    );
  } else if (filterType === 'hashFilter') {
    yield put(
      fetchHashPersonFilter({
        data: params,
        page: 1,
        sorting,
      })
    );
  } else {
    yield put(
      fetchPupilPersonData({
        page: 1,
        sorting,
      })
    );
  }
}

function* pupilPersonSaga() {
  yield takeLeading(fetchPupilPersonData, getPupilPersonData);
  yield takeLatest(fetchSelectedPupilPerson, getSelectedPupilPerson);
  yield takeLatest(fetchHashPersonFilter, getHashPupilPerson);
  yield takeLeading(fetchPupilDataWithFilter, getPupilPersonDataWithFilter);
  yield takeLatest(fetchAfterFilter, onAfterFilter);
  yield takeLatest(fetchFilterType, filterType);
  yield takeLatest(setTableSortOrder, sort);
}

export default pupilPersonSaga;
