import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Routes from 'enums/Routes';
import { eduProviderRoleTableColumns } from 'constants/tableColumns';
import PagesList from 'enums/PagesList';
import { getPeriodTimeString } from 'utils/format/timeFormat';
import Table from 'components/table/Table';
import initialSortTable from 'constants/sortTable';
import { getSelectedEduProviderAllInfo } from 'store/registry_pr/eduProviderPerson';
import { getStatus } from 'utils/mappers/getStatus';

const EduProviderRoleList = () => {
  const navigate = useNavigate();

  let eduProviderPerson = useSelector(getSelectedEduProviderAllInfo);

  eduProviderPerson = eduProviderPerson.filter((item: any) => {
    return item.resource.resourceType === 'EduProviderRole'
  }).map((item: any) => {
    return item.resource;
  })

  const getRowData = useMemo(() => {
    return eduProviderPerson.map((item: any) => {
      const { id, location, name, status, period, code } = item;
      const { start, end } = period;
      return {
        id,
        location,
        name,
        status: getStatus(status),
        code: code?.display,
        period: getPeriodTimeString(start, end),
      };
    });
  }, [eduProviderPerson]);

  const showEduProviderRole = (id: string) => {
    navigate(`${Routes.EDU_PROVIDER_ROLE}/${id}`);
  };

  return (
    <Table
      withSearch={false}
      totalItems={0}
      numberOfPage={0}
      page={0}
      columns={eduProviderRoleTableColumns}
      rowData={getRowData}
      componentName={PagesList.Edu_Provider_Role}
      onChangePage={() => {}}
      sortTable={initialSortTable}
      onSetSortOrder={() => {}}
      rowAction={showEduProviderRole}
      contextMenuItems={undefined}
      paginationNeeded={false}
    />
  );
};

export default EduProviderRoleList;
