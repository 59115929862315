import {
  Organization,
  OrganizationData,
  OrgFilterModel,
} from 'interfaces/';
import { PAGE_SIZE } from 'constants/common';
import { OrganizationState } from './types';

export const initialData: OrganizationData = {
  data: [],
  pageNumber: 1,
  pageSize: PAGE_SIZE,
  totalItems: 0,
};

export const initialFilterData: Partial<OrgFilterModel> = {
  _lastUpdated: '',
  address: '',
  'address-city': '',
  'address-country': '',
  'address-postalcode': '',
  'address-state': '',
  identifier: '',
  'name:contains': '',
  partof: '',
};

export const initialSelectedOrganization = {} as Organization;

export const initialState: OrganizationState = {
  organizationList: initialData,
  currentPage: 1,
  selectedOrganization: initialSelectedOrganization,
  filter: initialFilterData,
  filterQueryParam: ''
};
