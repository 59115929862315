import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EduProviderPersonFilterModel } from 'interfaces/';
import { initialFilterData } from 'store/registry_ob/pupilPerson/initialData';
import {
  genderSelectValues,
  getGenderValueByFieldName,
  getTypeValueByFieldName,
  typeDocumentSelectValues,
} from 'constants/selectValuesMap';
import { getHashPayload, HashPayload } from 'constants/parametersHash';
import { filterTypeUtil } from 'utils/isFilterUtils';
import s from 'components/commonStyles/filter.module.scss';
import { labels } from 'constants/labels';
import {
  CustomDatePicker,
  CustomSelect,
  CustomTextField,
} from 'components/fields';
import FilterWrapper from 'components/filterWrapper';
import {regexpEmail, regexpPhoneNumber, regexpSnils} from "../../../constants/regexp";

type Props = {
  isOpen: boolean;
  onClose: (isChanges: boolean) => void;
  selectorFilter: (obj: any) => any;
  fetchHashPersonFilter: (payload: { data: any; page: number }) => any;
  fetchEduProviderPersonDataWithFilter: (payload: { page: number }) => any;
  fetchFilterType: (payload: { filterType: string }) => any;
  fetchPersonData: (payload: { page: number }) => any;
  setFilterData: (payload: { filter: any }) => any;
};

const CommonPersonFilter: React.FC<Props> = ({
  isOpen,
  onClose,
  selectorFilter,
  fetchHashPersonFilter,
  fetchEduProviderPersonDataWithFilter,
  fetchFilterType,
  fetchPersonData,
  setFilterData,
}) => {
  const eduProviderPersonFilter = useSelector(selectorFilter);
  const dispatch = useDispatch();

  const [filter, setFilter] = useState<Partial<EduProviderPersonFilterModel>>(
    eduProviderPersonFilter || initialFilterData
  );
  const [isValidateSnils, setValidateSnils] = useState(true);
  const [isValidateEmail, setValidateEmail] = useState(true);
  const [isValidatePhone, setValidatePhone] = useState(true);

  const genderData = genderSelectValues.map((item) => item.key);
  const typeDocData = typeDocumentSelectValues.map((item) => item.key);

  const inputHandler = (name: string, value: string | number | undefined) => {
    switch (name) {
      case 'gender': {
        const obj = getGenderValueByFieldName('key', value);
        value = obj?.value || '';
        break;
      }
      case 'requisite':
      case 'type': {
        if (name === 'type') {
          const obj = getTypeValueByFieldName('key', value);
          value = obj?.value || '';
        }
        setFilter({
          ...filter,
          document: {
            ...filter.document,
            [name]: value,
          },
        });
        return;
      }
      default: {
        break;
      }
    }
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  const onFetchEduProviderWithHashFilter = () => {
    const params: HashPayload = getHashPayload(filter);
    dispatch(fetchHashPersonFilter({ data: params, page: 1 }));
    onClose(true);
  };

  const onFetchPersonDataWithFilter = () => {
    dispatch(fetchEduProviderPersonDataWithFilter({ page: 1 }));
    onClose(true);
  };

  const resetValidate = () => {
    setValidateEmail(true);
    setValidatePhone(true);
    setValidateSnils(true);
  }

  const onSubmit = () => {
    dispatch(setFilterData({ filter }));
    const filterType = filterTypeUtil(filter);
    dispatch(fetchFilterType({ filterType }));

    if (filter.email?.length || filter.phone?.length || filter.snils?.length) {
      resetValidate();
      const isValidEmail = filter.email?.length ? regexpEmail.test(filter.email) : true;
      const isValidPhone = filter.phone?.length ? regexpPhoneNumber.test(filter.phone) : true;
      const isValidSnils = filter.snils?.length ? regexpSnils.test(filter.snils) : true;
      if (filter.email?.length) {
        setValidateEmail(isValidEmail);
      }
      if (filter.phone?.length) {
        setValidatePhone(isValidPhone);
      }
      if (filter.snils?.length) {
        setValidateSnils(isValidSnils);
      }
      if (isValidSnils && isValidPhone && isValidEmail) {
        if (filterType === 'hashFilter') {
          onFetchEduProviderWithHashFilter();
        } else {
          onFetchPersonDataWithFilter();
        }
      }
      return;
    }
    resetValidate();
    if (filterType === 'hashFilter') {
      onFetchEduProviderWithHashFilter();
    } else {
      onFetchPersonDataWithFilter();
    }
  };

  const onClearFilter = () => {
    setFilter(initialFilterData);
    resetValidate();
    dispatch(setFilterData({ filter: initialFilterData }));
    dispatch(fetchFilterType({ filterType: '' }));
    dispatch(fetchPersonData({ page: 1 }));
    onClose(true);
  };

  const fields = () => {
    return (
      <>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.birthdayFilter}</span>
          <CustomDatePicker
            className={s.field}
            name="birthdate"
            inputGrid={24}
            onDateChange={inputHandler}
            defaultValue={filter.birthdate || ''}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.emailFilter}</span>
          <CustomTextField
            className={s.field}
            name="email"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter.email || ''}
            isValidateError={!isValidateEmail}
            errorMessage={!isValidateEmail ? labels.errorEmailMessage : ''}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.genderFilter}</span>
          <CustomSelect
            className={s.field}
            name="gender"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={
              getGenderValueByFieldName('value', filter.gender)?.key
            }
            data={genderData}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.identifierFilter}</span>
          <CustomTextField
            className={s.field}
            name="identifier"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter.identifier || ''}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.phoneFilter}</span>
          <CustomTextField
            className={s.field}
            name="phone"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter.phone || ''}
            isValidateError={!isValidatePhone}
            errorMessage={!isValidatePhone ? labels.errorPhoneNumberMessage : ''}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.telecomFilter}</span>
          <CustomTextField
            className={s.field}
            name="telecom"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter.telecom || ''}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.family}</span>
          <CustomTextField
            className={s.field}
            name="family"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter.family}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.namePerson}</span>
          <CustomTextField
            className={s.field}
            name="given"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter.given}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.middle}</span>
          <CustomTextField
            className={s.field}
            name="middle"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter.middle}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.snils}</span>
          <CustomTextField
            className={s.field}
            name="snils"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter.snils}
            isValidateError={!isValidateSnils}
            errorMessage={!isValidateSnils ? labels.errorSnilsMessage : ''}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.typeDocument}</span>
          <CustomSelect
            className={s.field}
            name="type"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={
              getTypeValueByFieldName('value', filter.document?.type)?.key
            }
            data={typeDocData}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.requisiteDocument}</span>
          <CustomTextField
            className={s.field}
            name="requisite"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter.document?.requisite}
          />
        </div>
      </>
    );
  };

  return (
    <FilterWrapper
      isOpen={isOpen}
      onClose={onClose}
      okAction={onSubmit}
      cancelAction={onClearFilter}
    >
      {fields()}
    </FilterWrapper>
  );
};

export default CommonPersonFilter;
