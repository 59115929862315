import { EduProviderPersonFilterModel } from 'interfaces/';

function isHashFilter(filters: Partial<EduProviderPersonFilterModel>) {
  return (
    filters.birthdate?.length &&
    filters.middle?.length &&
    filters.snils?.length &&
    filters.document?.type?.length &&
    filters.document?.requisite?.length &&
    filters.family?.length &&
    filters.given?.length
  );
}

export const filterTypeUtil = (filters: Partial<EduProviderPersonFilterModel>) => {
  if (isHashFilter(filters)) {
    return 'hashFilter';
  }
  return 'filter';
};
