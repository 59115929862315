import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Routes from 'enums/Routes';
import { nextOfKinTableColumns } from 'constants/tableColumns';
import PagesList from 'enums/PagesList';
import { getPeriodTimeString } from 'utils/format/timeFormat';
import { getSelectedPupilAllInfo } from 'store/registry_ob/pupilPerson';
import Table from 'components/table/Table';
import initialSortTable from 'constants/sortTable';
import Type from 'enums/Type';
import { getStatus } from 'utils/mappers/getStatus';

const NextOfKinList = () => {
  const navigate = useNavigate();

  let pupilPerson = useSelector(getSelectedPupilAllInfo);

  pupilPerson = pupilPerson
    .filter((item: any) => {
      return item.resource.resourceType === Type.NextOfKin;
    })
    .map((item: any) => {
      return item.resource;
    });

  const getRowData = useMemo(() => {
    return (
      pupilPerson?.map((item: any) => {
        const { id, relationship, status, period } = item;
        const { start, end } = period;
        return {
          id,
          relationship: relationship?.display,
          status: getStatus(status),
          period: getPeriodTimeString(start, end),
        };
      }) || []
    );
  }, [pupilPerson]);

  const showNextOfKin = (id: string) => {
    navigate(`${Routes.NEXT_OF_KIN_PUPIL}/${id}`);
  };

  return (
    <Table
      withSearch={false}
      totalItems={0}
      numberOfPage={0}
      page={0}
      columns={nextOfKinTableColumns}
      rowData={getRowData}
      componentName={PagesList.Next_Of_Kin_Person}
      onChangePage={() => {}}
      sortTable={initialSortTable}
      onSetSortOrder={() => {}}
      rowAction={showNextOfKin}
      contextMenuItems={undefined}
      paginationNeeded={false}
    />
  );
};

export default NextOfKinList;
